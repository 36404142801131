import React from "react";
import { NavLink } from "react-router-dom";
import { THEMES } from "src/constatnts";
import { Logo } from "../logo";
import Divider from "../Divider";

import './style.css'
import { ROUTES } from "../navigation/nav";
import Spacer from "../Spacer";
import { useTranslation } from "react-i18next";

const ProductsElements = () => {
  const { t } = useTranslation()
  return (
    <div className="column mr67 mob-mr-39">
      <p className="footer-links-group-header">{t('Products')}</p>
      <NavLink className='footer-links-group-item' to={ROUTES.aiPoweredCyberAttackSimulation}>{t('AI-powered cyber attack simulation')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.securityAwarenessTrainings}>{t('Security Awareness Trainings')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.emailThreatReportButton}>{t('Email Threat Report Button')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.intelligentThreatResponse}>{t('Intelligent Threat Response')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.gamificationPlatform}>{t('Gamification platform')}</NavLink>
    </div>
  )
}
const SolutionsElements = () => {
  const { t } = useTranslation()
  return (
    <div className="column mr92 mob-mr-0">
      <p className="footer-links-group-header">{t('Solutions')}</p>
      <NavLink className='footer-links-group-item' to={ROUTES.socialEngineeringPrevention}>{t('Social engineering prevention')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.behavioralChange}>{t('Behavioral change and employee engagement')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.comprehensiveCybersecurity}>{t('Comprehensive cybersecurity education')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.proactiveRiskAssessment}>{t('Proactive risk assessment and management')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.globalWorkforceDefense}>{t('Global workforce defense')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.achieveAndMaintainRobust}>{t('Achieve and maintain robust compliance with ISO 27001, PCI, HIPPA, GDPR, SOC 2, NIS 2 regulations')}</NavLink>
    </div>
  )
}
// const WhyElements = () => (
//   <div className="column mr90">
//     <p className="footer-links-group-header">Why Cyberlift</p>
//     <NavLink className='footer-links-group-item' to={ROUTES.aiPoweredPlatform}>AI-powered platform</NavLink>
//     <NavLink className='footer-links-group-item' to={ROUTES.cyberliftVsCompetitors}>Cyberlift vs. Competitors</NavLink>
//   </div>
// )
const CompanyElements = () => {
  const { t } = useTranslation()
  return (
    <div className="column">
      <p className="footer-links-group-header">{t('Company')}</p>
      <NavLink className='footer-links-group-item' to={ROUTES.whyCyberlift}>{t('Why Cyberlift')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.aboutUs}>{t('About us')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.pricing}>{t('Pricing')}</NavLink>
      <NavLink className='footer-links-group-item' to={ROUTES.pricing}>{t('Contacts')}</NavLink>
      {/* <NavLink className='footer-links-group-item' to={ROUTES.newsroom}>Newsroom</NavLink> */}
      {/* <NavLink className='footer-links-group-item' to={ROUTES.partners}>Partners</NavLink> */}
      {/* <NavLink className='footer-links-group-item' to={ROUTES.careers}>Careers</NavLink> */}
      {/* <NavLink className='footer-links-group-item' to={ROUTES.securityCompliance}>Security & compliance</NavLink> */}
      {/* <NavLink className='footer-links-group-item' to={ROUTES.technologies}>Technologies</NavLink> */}
    </div>
  )
}


export const Footer = ({ theme, style }) => {
  const { t } = useTranslation()
  return (
    <section className={(theme == THEMES.light ? 'light' : theme == THEMES.semiLight ? 'semi-light' : 'dark') + ' footer'} style={{ ...style }}>
      <div className="container">
        <div className="row">
          <NavLink to='/'>
            <Logo theme={theme} />
          </NavLink>
        </div>
        <div className="row mob-hide" style={{ marginBottom: '30px' }}>
          <ProductsElements />
          <SolutionsElements />
          {/* <WhyElements /> */}
          <CompanyElements />
        </div>
        <div className="hide mob-show pb150">
          <div className="row">
            <div className="column">
              <ProductsElements />
              {/* <WhyElements /> */}
              <CompanyElements />

            </div>
            <div className="column">
              <SolutionsElements />
              <NavLink className={'footer-link-item'} to={ROUTES.pricing}>{t('Pricing')}
                <svg style={{ marginLeft: 8, marginBottom: '-2px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill={theme == THEMES.dark ? '#fff' : "#1D1D29"} />
                </svg>
              </NavLink>
              <a target='_blank' className='footer-link-item' href="/privacy-policy">{t('Privacy policy')}</a>
              <a target='_blank' className='footer-link-item' href="/terms">{t('Terms of Use')}</a>
              <a target='_blank' className='footer-link-item' href="/data-processing-agreement">{t('Data Processing Agreement')}</a>
              {/* <NavLink className={'footer-link-item'} to={ROUTES.privacyPolicy}>Privacy policy</NavLink> */}
              {/* <NavLink className={'footer-link-item'} to={ROUTES.termsOfUse}>Terms of Use</NavLink> */}
            </div>
          </div>
        </div>
        <div className="row mob-hide pt100">
          <a target='_blank' className='footer-link-item' href="/privacy-policy">{t('Privacy policy')}</a>
          <a target='_blank' className='footer-link-item' href="/terms">{t('Terms of Use')}</a>
          <a target='_blank' className='footer-link-item' href="/data-processing-agreement">{t('Data Processing Agreement')}</a>
          {/* <NavLink className={'footer-link-item'} to={ROUTES.privacyPolicy}>Privacy policy</NavLink>
          <NavLink className={'footer-link-item'} to={ROUTES.termsOfUse}>Terms of Use</NavLink> */}
          <Spacer />
          <NavLink className={'footer-link-item'} to={ROUTES.pricing}>{t('Pricing')}
            <svg style={{ marginLeft: 8, marginBottom: '-2px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill={theme == THEMES.dark ? '#fff' : "#1D1D29"} />
            </svg>
          </NavLink>
        </div>
        <Divider style={theme == THEMES.dark && { background: '#2F2F2F' }} />
        <div className="column">
          <img src="images/iso27001.png" className="iso-27001-logo" alt="" />
          <p className="copyright-text">
            Copyright © 2025 Cyberlift. All Rights reserved
          </p>
          <br />
          <p className="copyright-text">CYBERLIFT PROSTA SPÓŁKA AKCYJNA with its registered office in Warsaw (02-001), al. Aleje Jerozolimskie 85/21 entered into the Register of Entrepreneurs of the National Court Register under KRS number 0001060890, whose files are kept by the District Court for the capital city of Warsaw in Warsaw, NIP 5223272287.</p>
          <br />
          <p className="copyright-text">Cyberlift is a global human risk management platform in the fight against cybercrime, protecting organizations and their employees around
            the world by preventing phishing attacks using behavioral science and AI.</p>
        </div>
        <div className="row"></div>
      </div>
    </section>


  )
}
